import { render, staticRenderFns } from "./ThreadRecipient.vue?vue&type=template&id=52489a4a&"
import script from "./ThreadRecipient.ts?vue&type=script&lang=ts&"
export * from "./ThreadRecipient.ts?vue&type=script&lang=ts&"
import style0 from "./ThreadRecipient.sass?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QAvatar,QIcon,QBtn});
